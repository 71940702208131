header {
  z-index: 1001;
}
.siderightview{
  position: fixed;
    top: 65px;
    right: 15px;
    z-index: 1004;
}
/* .booth-list-slider .MuiDrawer-paper{
  top: 64px;
  height: calc(100% - 64px)
} */
.main-container{
  display:flex;
  position: relative;
}
.toolbar{
  /* position: fixed;
  left:0;
  top:64px; */
  /* width: 170px; */
  height: 100%;
  z-index: 1000;
  /* background: #e0e0e0; */
  padding: 25px 10px;
  /* border-right: 3px solid #707070; */
}
.canvas-container{
  padding-left: 0;
  padding-top:64px
}
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  cursor: move;
}
.furniture{
  /* text-align: left; */
}

.controls {
  left: 10px; 
  top: 68.8px;
  position: fixed;
  transition: 500ms opacity;
  z-index: 1000;
}

.controls>button {
  min-width: 0px;
  padding: 0;
  background: #fff;
  outline: none;
  border: none;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
  border-radius: 2px;
  display: block;
  font-size: 0.75rem;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  color: #777;
  display:flex;
  align-items: center;
  justify-content: center;
}
.controls>button:hover {
  background-color: rgb(227 227 227 / 80%);
}