.main-container{
  display:flex
}
.toolbar{
  position: fixed;
  left:0;
  top:0;
  width: 170px;
  height: 100%;
  z-index: 10000;
  background: #e0e0e0;
  padding: 25px 10px;
  border-right: 3px solid #707070;
}
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  cursor: move;
}

button.active {
  background-color: #32475e!important
}

.sketch textarea{
  position: unset!important;
}